<template>
	<div id="app">
		<LoadingScreen />
		<nonet v-if="!connection" />
		<updateavl />
		<background v-if="$store.getters.isBG" />
		<div v-else class="fixed top-0 left-0 w-full h-full nobg"></div>
		<div class="container mx-auto sm:mt-32 mt-24 p-4">
			<router-view />
			<div
				v-if="$store.getters.isLoggedIn"
				class="fixed flex flex-col right-5 top-5 select-none rounded-lg aborder mendrw"
			>
				<div
					class="bg-gray-800 px-5 py-2 flex"
					@click="showAdminMenu = !showAdminMenu"
				>
					<span v-if="showAdminMenu">Admin Menu</span>
					<span v-if="showAdminMenu" class="ml-3 cursor-pointer"
						><svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							stroke="#ffffff"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						>
							<line x1="18" y1="6" x2="6" y2="18"></line>
							<line x1="6" y1="6" x2="18" y2="18"></line></svg
					></span>
					<span v-if="!showAdminMenu" class="cursor-pointer"
						><svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							stroke="#ffffff"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						>
							<line x1="3" y1="12" x2="21" y2="12"></line>
							<line x1="3" y1="6" x2="21" y2="6"></line>
							<line x1="3" y1="18" x2="21" y2="18"></line></svg
					></span>
				</div>
				<div class="flex flex-col cursor-pointer" v-if="showAdminMenu">
					<a
						@click.prevent="goto('/')"
						href="/"
						class="pl-5 py-1 bg-red-600"
						>Home</a
					>
					<a
						@click.prevent="goto('/register')"
						href="/register"
						class="pl-5 py-1 bg-red-600"
						>Register</a
					>
					<a
						@click.prevent="goto('/lucky')"
						href="/lucky"
						class="pl-5 py-1 bg-red-600"
						>Lucky</a
					>
					<a
						@click.prevent="goto('/submit')"
						href="/submit"
						class="pl-5 py-1 bg-red-600"
						>Submit</a
					>
					<a
						@click.prevent="goto('/runners')"
						href="/runners"
						class="pl-5 py-1 bg-red-600"
						>Runners</a
					>
					<a
						v-if="!ismobile"
						class="pl-5 py-1 bg-red-600 cursor-pointer"
						@click="showSettime = !showSettime"
						>Update Timer</a
					>
					<a
						@click.prevent="goto('/logout')"
						href="/logout"
						class="pl-5 py-1 bg-red-600"
						>Logout</a
					>
				</div>
			</div>
			<updatetimer v-if="$store.getters.isLoggedIn && showSettime" />
		</div>
	</div>
</template>
<script>
	import background from "./components/Common/background.vue";
	import LoadingScreen from "./views/Loading.vue";
	import updatetimer from "./components/Common/updatetimer.vue";
	import nonet from "./components/Common/nonet.vue";
	import updateavl from "./components/Common/updateavl.vue";
	export default {
		data() {
			return {
				showAdminMenu: false,
				showSettime: false,
				connection: true,
				connectioncheckerintervval: null,
				ismobile: true,
			};
		},
		mounted() {
			this.checkconnections();
			this.connectioncheckerintervval = setInterval(
				this.checkconnections,
				1000
			);
			if (!this.detectMob()) this.ismobile = false;
			document.addEventListener("keydown", this.loginredir);
		},
		beforeUnmount() {
			clearInterval(this.connectioncheckerintervval);
		},
		methods: {
			loginredir(e) {
				if (!this.$store.getters.isLoggedIn) {
					if (
						e.code == "KeyL" &&
						e.altKey == true &&
						e.isTrusted == true &&
						this.$route.name !== "login"
					) {
						e.preventDefault();
						this.$router.push("/login");
					}
				}
			},
			checkconnections() {},
			havecon() {
				if (this.connection == false)
					this.$router.go(this.$router.currentRoute);
				this.connection = true;
			},
			nocon() {
				this.connection = false;
			},
			hidemenu() {
				this.showAdminMenu = false;
			},
			goto(page) {
				this.$router
					.push(page)
					.then(this.hidemenu)
					.catch(e => {
						e;
					});
			},
			detectMob() {
				var isMobile = /iphone|ipod|android|ie|blackberry|fennec/.test(
					navigator.userAgent.toLowerCase()
				);
				return isMobile;
			},
		},
		components: {
			background,
			LoadingScreen,
			updatetimer,
			nonet,
			updateavl,
		},
	};
</script>

<style lang="scss">
	html {
		background: #121212;
		color: #fff;
	}
	.aborder {
		border: 1px solid transparent;
		overflow: hidden;
	}
	.font-spantaran {
		font-family: "Spantaran";
	}
</style>

<style scoped>
	.nobg {
		background: #0c1d49;
		z-index: -50;
	}
</style>
