<template>
	<div
		v-if="date"
		class="fixed top-1/2 left-1/2 transform p-5 bg-red-800 rounded-lg space-y-4"
	>
		<div class="flex">
			<input
				v-model="date"
				class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				type="date"
				:placeholder="date"
				autofocus
			/>
			<div class="flex justify-center text-gray-900 ml-4">
				<div
					class="px-2 bg-white rounded-lg shadow-xl flex items-center"
				>
					<select
						v-model="hour"
						name="hours"
						class="bg-transparent text-center text-xl appearance-none outline-none"
					>
						<option
							v-for="i in 24"
							:key="i"
							:value="i - 1"
							:selected="i - 1 == hour"
						>
							{{ i - 1 }}
						</option>
					</select>
					<span class="text-xl mx-1 mb-1">:</span>
					<select
						v-model="min"
						name="minutes"
						class="bg-transparent text-center text-xl appearance-none outline-none"
					>
						<option
							v-for="i in 60"
							:key="i"
							:value="i - 1"
							:selected="i - 1 == min"
						>
							{{ i - 1 }}
						</option>
					</select>
				</div>
			</div>
		</div>
		<div class="flex justify-center">
			<button
				v-if="!isSaving"
				class="h-2 bg-gray-100 rounded w-20 h-10 text-gray-900"
				@click="updatetimer"
			>
				Save
			</button>
			<button
				v-if="isSaving"
				class="h-2 bg-gray-100 rounded w-24 h-10 text-gray-700 flex items-center justify-center"
			>
				Saving
				<svg
					class="animate-spin -mr-1 ml-3 h-5 w-5"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
				>
					<circle
						class="opacity-25"
						cx="12"
						cy="12"
						r="10"
						stroke="currentColor"
						stroke-width="4"
					></circle>
					<path
						class="opacity-75"
						fill="currentColor"
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
					></path>
				</svg>
			</button>
		</div>
	</div>
	<div
		v-else
		class="fixed flex-col flex space-y-4 top-1/2 left-1/2 transform p-5 bg-blue-800 rounded-lg"
	>
		<div class="animate-pulse flex space-x-4">
			<div class="h-2 bg-gray-100 rounded w-40 h-10"></div>
			<div class="h-2 bg-gray-100 rounded w-20 h-10"></div>
		</div>
		<div class="animate-pulse flex justify-center">
			<button
				class="h-2 bg-gray-100 rounded w-20 h-10 text-gray-500"
				disabled
			>
				Save
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				date: null,
				hour: null,
				min: null,
				isSaving: false,
			};
		},
		mounted() {
			const self = this;
			this.$http
				.get(process.env.VUE_APP_API_ROOT + "/timer")
				.then(res => {
					self;
					if (res.status == 200) {
						self.date = self.format(
							new Date(res.data.timer),
							"yyyy-MM-dd"
						);
						self.hour = self.format(new Date(res.data.timer), "h");
						self.min = self.format(new Date(res.data.timer), "m");
					}
				})
				.catch(err => {
					console.log(err);
				});
		},
		methods: {
			format(x, y) {
				let z = {
					M: x.getMonth() + 1,
					d: x.getDate(),
					h: x.getHours(),
					m: x.getMinutes(),
					s: x.getSeconds(),
				};
				y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
					return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(
						-2
					);
				});

				return y.replace(/(y+)/g, function (v) {
					return x.getFullYear().toString().slice(-v.length);
				});
			},
			updatetimer() {
				const timerend = `${this.date} ${this.hour}:${this.min}:00`;
				this.isSaving = true;
				const self = this;
				this.$http
					.post(process.env.VUE_APP_API_ROOT + "/options", {
						timerend,
					})
					.then(() => {
						self.$router.go(this.$router.currentRoute);
					})
					.catch(e => {
						console.log(e);
						this.isSaving = false;
					});
			},
		},
	};
</script>

<style scoped>
	.transform {
		transform: translate(-50%, -50%);
	}
</style>
