<template>
    <div>
        <ul
            class="table mt-5 border-collapse text-center bg-white mx-auto shadow-sm text-blue-800"
            v-if="pagination_data.totalPages > 1"
        >
            <li
                v-if="pagination_data.currentPage > pagination_data.startPage && pagination_data.startPage !== 1"
                class="w-8 h-8 border border-gray-200 table-cell hover:border-blue-100"
            >
                <button
                    @click="onc(1)"
                    class="
                    hover:bg-primary
                    w-full
                    h-full
                    transition
                    duration-100
                    ease-in-out
                    focus:ring-2
                    focus:ring-blue-500
                    focus:outline-none
                    focus:ring-opacity-50
                    "
                >
                    «
                </button>
            </li>
            <li v-if="pagination_data.currentPage > pagination_data.startPage"
                @click="onc(pagination_data.currentPage - 1)"
                class="w-8 h-8 border border-gray-200 table-cell hover:border-blue-100">
                <button
                    class="
                    hover:bg-primary
                    w-full
                    h-full
                    transition
                    duration-100
                    ease-in-out
                    focus:ring-2
                    focus:ring-blue-500
                    focus:outline-none
                    focus:ring-opacity-50
                    "
                >
                    ‹
                </button>
            </li>
            <li
                v-for="p in pagination_data.pages"
                :key="p"
                class="w-8 h-8 border border-gray-200 table-cell hover:border-blue-100"
                @click="onc(p)"
            >
                    <button
                    v-if="p == pagination_data.currentPage"
                    class="
                        bg-blue-800
                        w-full
                        h-full
                        text-white
                        hover:bg-blue-900
                        transition
                        duration-100
                        ease-in-out
                        focus:ring-2
                        focus:ring-blue-500
                        focus:outline-none
                        focus:ring-opacity-50
                    "
                    >
                    {{ p }}
                    </button>
                    <button
                    v-else
                    class="
                        hover:bg-primary
                        w-full
                        h-full
                        transition
                        duration-100
                        ease-in-out
                        focus:ring-2
                        focus:ring-blue-500
                        focus:outline-none
                        focus:ring-opacity-50
                    "
                    >
                    {{ p }}
                    </button>
            </li>
            <li
                v-if="pagination_data.currentPage < pagination_data.endPage"
                class="w-8 h-8 border border-gray-200 table-cell hover:border-blue-100"
                @click="onc(pagination_data.currentPage + 1)"
            >
                <button
                    class="
                    hover:bg-primary
                    w-full
                    h-full
                    transition
                    duration-100
                    ease-in-out
                    focus:ring-2
                    focus:ring-blue-500
                    focus:outline-none
                    focus:ring-opacity-50
                    "
                >
                    ›
                </button>
            </li>
            <li
                v-if="pagination_data.currentPage < pagination_data.endPage && pagination_data.endPage !== pagination_data.totalPages"
                class="w-8 h-8 border border-gray-200 table-cell hover:border-blue-100"
                @click="onc(pagination_data.totalPages)"
            >
                <button
                    class="
                    hover:bg-primary
                    w-full
                    h-full
                    transition
                    duration-100
                    ease-in-out
                    focus:ring-2
                    focus:ring-blue-500
                    focus:outline-none
                    focus:ring-opacity-50
                    "
                >
                    »
                </button>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props: ["items", "onc", "maxPages"],
    data() {
        return{
            pagination_data: [],
            RmaxPages: this.maxPages ? this.maxPages : 5,
        }
    },
    mounted(){
        if (this.items) {
            const pagination_data = this.paginate(this.items);
            this.pagination_data = pagination_data;
        }
    },
    watch: {
        items() {
            if (this.items) {
                const pagination_data = this.paginate(this.items);
                this.pagination_data = pagination_data;
            }
        }
    },
    methods: {
        paginate(items) {
            let totalItems = items.totalitems;
            let currentPage = items.currentpage;
            let pageSize = items.perpage;
            let maxPages = this.RmaxPages;
            let totalPages = items.totalpage;
            let startPage,
                endPage,
                maxPagesBeforeCurrentPage,
                maxPagesAfterCurrentPage,
                startIndex,
                endIndex,
                pages;

            if (currentPage < 1) currentPage = 1;
            else if (currentPage > totalPages) currentPage = totalPages;
            if (totalPages <= maxPages)  {
                startPage = 1;
                endPage = totalPages;
            }
            else {
                maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
                maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
                if (currentPage <= maxPagesBeforeCurrentPage) {
                    startPage = 1;
                    endPage = maxPages;
                }
                else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                    startPage = totalPages - maxPages + 1;
                    endPage = totalPages;
                }
                else {
                    startPage = currentPage - maxPagesBeforeCurrentPage;
                    endPage = currentPage + maxPagesAfterCurrentPage;
                }
            }
            startIndex = (currentPage - 1) * pageSize;
            endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

            pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
                (i) => startPage + i
            );
            return {
                totalItems: totalItems,
                currentPage: currentPage,
                pageSize: pageSize,
                totalPages: totalPages,
                startPage: startPage,
                endPage: endPage,
                startIndex: startIndex,
                endIndex: endIndex,
                pages: pages,
            };
        }
    },
}
</script>