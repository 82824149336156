<template>
	<div>
		<div
			v-if="updating && cached"
			class="select-none fixed top-0 left-0 w-full px-4 py-2 bg-red-800 flex items-center gap-x-2 z-50"
		>
			<p class="flex items-center">
				<span class="font-bold">Downloading</span>
				<svg
					class="animate-spin ml-2 h-5 w-5 text-white"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
				>
					<circle
						class="opacity-25"
						cx="12"
						cy="12"
						r="10"
						stroke="currentColor"
						stroke-width="4"
					></circle>
					<path
						class="opacity-75"
						fill="currentColor"
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
					></path>
				</svg>
			</p>
		</div>
		<div
			v-if="updateExists"
			class="fixed top-0 left-0 w-full px-4 py-2 bg-blue-800 flex items-center gap-x-2 z-50"
		>
			<p>A newer version of this app is avaliable!</p>
			<button @click="forcereload" class="flex gap-1">
				<span class="font-bold">Install</span>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke="#ffffff"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				>
					<path
						d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"
					/>
				</svg>
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				registration: null,
				updateExists: false,
				updating: false,
				cached: false,
			};
		},
		created() {
			document.addEventListener("swUCached", this.swFromCache, {
				once: true,
			});
			document.addEventListener("swFromCache", this.swFromCache, {
				once: true,
			});
			document.addEventListener("swUpdateing", this.updatedownloading, {
				once: true,
			});
			document.addEventListener("swUpdated", this.updateAvailable);
		},
		methods: {
			updateAvailable(e) {
				this.registration = e.detail;
				this.updateExists = true;
				this.updating = false;
			},
			swFromCache() {
				this.cached = true;
			},
			swCached() {
				this.cached = false;
			},
			updatedownloading() {
				this.updating = true;
			},
			refreshApp() {
				this.updateExists = false;
				if (!this.registration || !this.registration.waiting) return;
				this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
			},
			forcereload() {
				this.updateExists = false;
				this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
				window.location.reload();
			},
		},
	};
</script>
