<template>
	<div class="home">
		<div
			class="min-w-screen bg-gray-900 flex flex-col gap-9 px-5 py-5 rounded-sm"
		>
			<diswinners />
		</div>
	</div>
</template>

<script>
	import diswinners from "../components/Runners/distance.vue";
	export default {
		name: "Home",
		data() {
			return {
				// isLoading: true,
				// totalDistance: "loading",
				// kminterval: null,
			};
		},
		mounted() {
			// const self = this;
			// const defaultt = this.$route.name == "Index.nobg" ? 5000 : 30000;
			// const refresh = this.$route.query.refresh
			// 	? this.$route.query.refresh
			// 	: defaultt;
			// self.getKm();
			// this.kminterval = setInterval(function () {
			// 	self.getKm();
			// }, refresh);
		},
		beforeUnmount() {
			//clearInterval(this.kminterval);
		},
		methods: {
			// getKm: function () {
			// 	const self = this;
			// 	this.$http
			// 		.get(process.env.VUE_APP_API_ROOT + "/total")
			// 		.then(function (responce) {
			// 			if (responce.status == 200) {
			// 				self.setKm(responce.data.total);
			// 			}
			// 		});
			// },
			// setKm($km) {
			// 	this.totalDistance = $km;
			// },
		},
		components: { diswinners },
	}; //today = new Date(timeElapsed);
</script>

<style lang="scss" scoped></style>
