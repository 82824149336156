<template>
	<div>
		<div
			id="loading"
			class="fixed top-0 left-0"
			:class="{ loadremove: !$store.getters.isLoading }"
		>
			<div class="spinner absolute">
				<img
					src="/img/bg/Synergy-Logo.png"
					alt="Synergy-Logo"
					class="mb-8"
				/>
				<div class="flex items-center justify-center">
					<div
						class="w-24 h-24 border-2 border-blue-900 rounded-full animate-spin spinnerborder"
					></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {
			const self = this;
			setTimeout(() => {
				self.isLoading = false;
			}, 1000);
		},
		name: "Loading",
	};
</script>

<style>
	#loading {
		@apply bg-white;
		z-index: 1000;
		height: 100%;
		width: 100%;
		transition: all 200ms ease-out;
	}
	.spinner {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.loadremove {
		transform: translateY(-100%);
	}
	.spinnerborder {
		border-right-color: transparent !important;
		border-left-color: transparent !important;
	}
</style>
