import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index";
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/index.nobg",
		name: "Index.nobg",
		component: Home,
		meta: {
			hideBG: true,
		},
	},
	{
		path: "/liveinfo",
		name: "info",
		component: () =>
			import(
				/* webpackChunkName: "chunk_app_views_page_live_info_vue" */ "../views/info.vue"
			),
		meta: {
			hideBG: true,
		},
	},
	{
		path: "/lucky",
		name: "Lucky",
		component: () =>
			import(
				/* webpackChunkName: "chunk_app_views_page_lucky_vue" */ "../views/lucky.vue"
			),
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: "/submit",
		name: "Submit",
		component: () =>
			import(
				/* webpackChunkName: "chunk_app_views_page_submit_vue" */ "../views/Submit.vue"
			),
	},
	{
		path: "/submit.html",
		name: "Submit.html",
		component: () =>
			import(
				/* webpackChunkName: "chunk_app_views_page_submit_vue" */ "../views/Submit.vue"
			),
	},
	{
		path: "/runners",
		name: "Runners",
		component: () =>
			import(
				/* webpackChunkName: "chunk_app_views_page_runners_vue" */ "../views/Runners.vue"
			),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/register",
		name: "Register",
		component: () =>
			import(
				/* webpackChunkName: "chunk_app_views_page_register_vue" */ "../views/Register.vue"
			),
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: "/login",
		name: "login",
		component: () =>
			import(
				/* webpackChunkName: "chunk_app_views_page_login_vue" */ "../views/Login.vue"
			),
	},
	{
		path: "/logout",
		name: "logout",
		component: () =>
			import(
				/* webpackChunkName: "chunk_app_views_page_logout_vue" */ "../views/logout.vue"
			),
	},
	{
		path: "/:catchAll(.*)",
		name: "NotFound",
		component: () =>
			import(
				/* webpackChunkName: "chunk_app_views_page_404_vue" */ "../views/pagenotfound.vue"
			),
		meta: {
			requiresAuth: false,
		},
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.afterEach(() => {
	setTimeout(() => {
		store.commit("loading", false);
	}, 500);
});

router.beforeEach((to, from, next) => {
	from;
	store.commit("loading", true);

	if (to.matched.some(record => record.meta.hideBG)) {
		store.dispatch("hideBG");
	} else {
		store.dispatch("showBG");
	}

	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (store.getters.isLoggedIn) {
			next();
			return;
		}
		next("/login");
	} else {
		next();
	}
});

export default router;
