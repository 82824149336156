<template>
	<div class="block w-full overflow-x-auto">
		<table
			class="hidden md:table items-center bg-transparent w-full border-collapse"
		>
			<thead>
				<tr>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Rank
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						BIB
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Name
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Total Distance run
					</th>
				</tr>
			</thead>
			<tbody v-if="items">
				<tr v-for="(item, i) in items" :key="i">
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{ (peginate.currentpage - 1) * 10 + (i + 1) }}
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{ padNumber(item.bib, 4) }}
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{ item.name }}
					</th>

					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<span v-if="item.totalApprovedDistance > 100">
							100 km Completed
						</span>
						<span v-else>
							{{
								new Intl.NumberFormat("en-US").format(
									item.totalApprovedDistance
								)
							}}
							km
						</span>
					</th>
				</tr>
			</tbody>
			<tbody v-else>
				<tr class="animate-pulse" v-for="i in 10" :key="i">
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
				</tr>
			</tbody>
		</table>
		<table
			v-if="items"
			class="table md:hidden w-full table-fixed text-xs sm:text-md"
		>
			<thead>
				<tr>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						ATHLETE
					</th>
					<th
						class="text-right px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
					>
						Distance
					</th>
				</tr>
			</thead>
			<tbody v-for="(item, i) in items" :key="i">
				<tr class="border-b border-blue-50 border-opacity-20">
					<td class="py-1 pl-6 max-w-1/2">
						<p class="flex gap-4 items-center">
							<span>
								{{ (peginate.currentpage - 1) * 10 + (i + 1) }}
							</span>
							<span class="flex flex-col justify-center">
								<span>
									{{ item.name }}
								</span>
								<span style="font-size: 10px">
									BIB: {{ padNumber(item.bib, 4) }}
								</span>
							</span>
						</p>
					</td>
					<td class="py-1 pr-6 text-right">
						<span v-if="item.totalApprovedDistance > 100">
							100 km Completed
						</span>
						<span v-else>
							{{
								new Intl.NumberFormat("en-US").format(
									item.totalApprovedDistance
								)
							}}
							km
						</span>
					</td>
				</tr>
				<!-- <p>
					{{ (peginate.currentpage - 1) * 10 + (i + 1) }}
				</p>
				<p>
					{{ padNumber(item.bib, 4) }}
				</p>
				<p>
					{{ item.name }}
				</p>

				<p>
					{{
						new Intl.NumberFormat("en-US").format(
							item.totalApprovedDistance
						)
					}}
					km
				</p> -->
			</tbody>
		</table>
		<div v-if="items && items.length < 1" class="text-center">
			No submission yet, Please check back later!
		</div>
		<!-- <div v-if="peginate && peginate.totalitems">
			Total: {{ peginate.totalitems }}
		</div> -->
		<pagination v-if="peginate" :items="peginate" :onc="getPage" />
	</div>
</template>

<script>
	import pagination from "../Common/pegination.vue";
	export default {
		data() {
			return {
				items: null,
				peginate: null,
				img: null,
				cpageno: 1,
			};
		},
		mounted() {
			this.$http
				.get(process.env.VUE_APP_API_ROOT + "/runner/mostrun?limit=10")
				.then(this.handleItems)
				.catch(this.handleerr);
		},
		methods: {
			padNumber(num, digits) {
				num = num.toString();
				while (digits > num.length) {
					num = "0" + num;
				}
				return num;
			},
			handleItems(items) {
				if (items.status == 200) {
					this.items = items.data.items;
					this.peginate = items.data.meta;
					if (
						this.cpageno > items.data.meta.totalpage &&
						this.cpageno !== 1
					) {
						this.cpageno = items.data.meta.totalpage;
						this.getPage();
					}
				}
			},
			handleerr(err) {
				console.log(err);
			},
			getPage(pageno = null) {
				this.items = null;
				if (pageno) this.cpageno = pageno;
				this.$http
					.get(
						process.env.VUE_APP_API_ROOT +
							"/runner/mostrun?limit=10&page=" +
							this.cpageno
					)
					.then(this.handleItems)
					.catch(this.handleerr);
			},
			handleaction() {
				this.getPage();
			},
		},
		components: {
			pagination,
		},
	};
</script>
